<template>
  <v-dialog v-model="dialog" class="scroll" scrollable max-width="1000px" :fullscreen="$vuetify.display.mobile">
    <v-card>
      <!-- <v-card-title class="pa-4"> {{ title }} </v-card-title> -->
      <v-card-title class="pa-0">
        <div class="pt-4 px-4">
          <b>
            <template v-if="isInsert"> Novo produto </template>
            <template v-else>
              {{ form.name }}
            </template>
          </b>
        </div>

        <v-tabs v-model="tab" size="x-large" color="primary">
          <v-tab :value="1">Cadastro</v-tab>
          <v-tab :value="2" :disabled="!canEditStock">Estoque</v-tab>
          <!-- <v-tab :value="3">Condicional</v-tab> -->
        </v-tabs>
        <v-divider />
      </v-card-title>
      <v-card-text class="px-4">
        <v-window v-model="tab">
          <v-window-item :key="1" :value="1">
            <ProductForm />
          </v-window-item>
          <v-window-item :key="2" :value="2">
            <ProductStockTransactions :productId="productId" />
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end pa-4">
        <template v-if="tab == 1">
          <AppCancelBtn @click="dialog = false" />
          <AppConfirmBtn @click="handleStore()" />
        </template>
        <template v-if="tab !== 1">
          <AppCancelBtn @click="dialog = false" />
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const productForm = useProductFormStore();

const { form, isInsert } = storeToRefs(productForm);

const $loading = useLoading();

const emit = defineEmits(["store"]);

const dialog = ref(false);

const tab = ref(1);

const productId = ref(null);

const title = computed(() =>
  isInsert.value ? "Novo produto" : "Editar produto"
);

const canEditStock = ref(false);

const add = (params) => {
  open();

  if (params) {
    form.value.product_variants[0].gtin = params.gtin;
  }
};

const clone = async (id) => {
  await open(id);

  form.value.id = null;
  form.value.code = null;

  for (let i = 0; i < form.value.product_variants.length; i++) {
    form.value.product_variants[i].id = null;
    form.value.product_variants[i].code = null;
    form.value.product_variants[i].initial_stock = 0;
  }
};

const open = async (id = null, params = null) => {
  tab.value = 1;

  canEditStock.value = false;

  productId.value = id;

  productForm.$reset();

  if (id) {
    $loading.start();

    await loadProduct(id);
  }

  if (params) {
    setParams(params);
  }

  dialog.value = true;
};

const loadProduct = async (id) => {
  await productForm
    .load(id)
    .then((response) => $loading.stop())
    .catch((error) => $loading.stop());

  if (form.value.control_stock) {
    canEditStock.value = true;
  }
};

const setParams = (params) => {
  form.value.name = params.name; 
    form.value.description = params.description;

    if (params.category_id && params.category_name) {
      form.value.category = {
        id: params.category_id,
        name: params.category_name,
      }
    }
};

const handleStore = async () => {
  $loading.start();

  productForm.setOriginalPrice()

  await productForm
    .store()
    .then((response) => {
      emit("store", response);

      dialog.value = false;
      $loading.stop();
    })
    .catch(() => $loading.stop());
};

defineExpose({
  open,
  add,
  clone,
});
</script>

<style lang="scss" scoped>
.scroll {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #e4e4e4 transparent;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cabfbf;
  }
}
</style>