<template>
  <v-dialog v-model="dialog" max-width="800px" :fullscreen="$vuetify.display.mobile" scrollable>
    <v-card>
      <v-card-title class="pa-4 d-flex align-center justify-space-between">
        <span class="mr-4">Variações</span>
        <v-chip-group v-model="status" multiple mandatory>
          <v-chip :value="true" filter size="small" color="primary">
            Ativos
          </v-chip>
          <v-chip :value="false" filter size="small" color="error">
            Inativos
          </v-chip>
        </v-chip-group>
      </v-card-title>
      <v-card-text class="pa-4">
        <template v-for="(productVariant, index) of variants">
          <ProductVariantSection :product="form" :productVariant="productVariant" />
          <AppSwitch v-if="!isInsertProductVariant(productVariant) && productVariantsForm.length > 1"
            v-model="productVariant.status" label="Cadastro Ativo" color="primary"
            :disabled="disableSwitch(productVariant)" />
          <template v-if="canRemove(productVariant)">
            <AppBtn class="mt-4" size="small" variant="tonal" @click="handleRemove(index)">Remover</AppBtn>
          </template>
          <v-divider class="my-4" />
        </template>
        <v-row>
          <v-col>
            <AppBtn color="primary" size="small" variant="outlined" @click="handleAddVariant">Adicionar</AppBtn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer />
        <AppCancelBtn @click="dialog = false" />
        <AppConfirmBtn @click="handleStore()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const productForm = useProductFormStore();

const { form, isInsert } = storeToRefs(productForm);

const dialog = ref(false);

const status = ref([true]);

const productVariantsForm = ref([]);

const variants = computed(() => {
  return productVariantsForm.value.filter((variant) => {
    if (status.value.length === 1) {
      return variant.status === status.value[0];
    }

    return true;
  });
});

const isInsertProductVariant = (productVariant) => {
  return !productVariant.id;
};

const open = () => {
  dialog.value = true;

  productVariantsForm.value = JSON.parse(
    JSON.stringify(form.value.product_variants)
  );
};

const handleAddVariant = () => {
  let productVariant = {
    code: null,
    gtin: null,
    name: null,
    commission: 0,
    price: 0,
    original_price: 0,
    promotional_price: null,
    cost: 0,
    stock_min: 0,
    stock_max: 0,
    initial_stock: 0,
    status: true,
  };

  productVariantsForm.value.push(productVariant);
};

const handleRemove = (index) => {
  productVariantsForm.value.splice(index, 1);
};

const canRemove = (productVariant) => {
  return !productVariant.id && productVariantsForm.value.length > 1;
};

const handleStore = async () => {
  form.value.product_variants = productVariantsForm.value;

  dialog.value = false;
};

const disableSwitch = (productVariant) => {
  const activeVariants = productVariantsForm.value.filter(variant => variant.status);
  return productVariantsForm.value.length <= 1 || (activeVariants.length <= 1 && productVariant.status);
};

defineExpose({
  open,
});
</script>

<style lang="scss" scoped></style>