<template>
  <v-dialog v-model="dialog" max-width="800px" :fullscreen="$vuetify.display.mobile">
    <v-card>
      <v-card-title class="pa-4"> {{ title }} </v-card-title>
      <v-card-text class="pa-4">
        <ProductVariantSection :productVariant="productVariant" />
        <AppSwitch v-model="productVariant.status" label="Cadastro Ativo" color="primary" :disabled="disableSwitch" />
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer />
        <AppCancelBtn @click="dialog = false" />
        <AppConfirmBtn @click="handleStore()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const emit = defineEmits(["store"]);

const productForm = useProductFormStore();

const { form, isInsert, variants } = storeToRefs(productForm);

const dialog = ref(false);

const editIndex = ref(null);

const title = computed(() =>
  isInsert.value ? "Nova variação" : "Editar variação"
);

const productVariant = ref({});

const hasVariants = computed(() => variants.value.length > 0);

const disableSwitch = computed(() => {
  const activeVariants = variants.value.filter(variant => variant.status);
  return variants.value.length <= 1 || (activeVariants.length <= 1 && productVariant.value.status);
});

const open = (index) => {
  editIndex.value = index;

  productVariant.value = JSON.parse(JSON.stringify(variants.value[index]));

  dialog.value = true;
};

const handleStore = async () => {
  let productVariantIndex = form.value.product_variants.findIndex(
    (variant) => variant === variants.value[editIndex.value]
  );

  form.value.product_variants[productVariantIndex] = productVariant.value;

  dialog.value = false;

  emit("store");
};

defineExpose({
  open,
});
</script>

<style lang="scss" scoped></style>